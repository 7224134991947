import HttpRequest from '@/resources/axios/core/http-request'
import store from '@/store'

class MemberProvider extends HttpRequest {
  getMemberList = (params) => {
    this.setHeader({
      key: 'Authorization',
      value: `Bearer ${store.getters['auth/user'].accessToken}`
    })
    return this.get(
      `/members?page=${params.page}&limit=${params.limit}&status=ACTIVE&eagerLoad=EmergencyContacts&orderBy=id&sortBy=desc${params.filter}`
    )
  }

  getMemberOne = (param) => {
    this.setHeader({
      key: 'Authorization',
      value: `Bearer ${store.getters['auth/user'].accessToken}`
    })
    return this.get(`/members/${param}`)
  }

  updateMember = (param, payload) => {
    this.setHeader({
      key: 'Authorization',
      value: `Bearer ${store.getters['auth/user'].accessToken}`
    })
    return this.put(`/members/${param}`, payload)
  }

  deleteMember = (param) => {
    this.setHeader({
      key: 'Authorization',
      value: `Bearer ${store.getters['auth/user'].accessToken}`
    })
    return this.delete(`/members/${param}`)
  }
}

export default new MemberProvider()
