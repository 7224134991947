var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"member"},[(_vm.$route.name === 'MemberUpdate')?_c('router-view'):_c('div',[_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('app.page.member.title'))+" ")])]),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"value":_vm.search,"placeholder":_vm.$t('app.placeholder.search'),"prepend-inner-icon":"mdi-magnify","dense":"","outlined":"","hide-details":""},on:{"input":function($event){return _vm.debounceSearching($event)}}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"placeholder":_vm.$t('app.placeholder.filter'),"items":_vm.filters,"item-text":"text","item-value":"value","clearable":"","dense":"","outlined":"","hide-details":""},model:{value:(_vm.selectedFilter),callback:function ($$v) {_vm.selectedFilter=$$v},expression:"selectedFilter"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.isLoading,"loading-text":_vm.$t('vuetify.loading'),"no-data-text":_vm.$t('vuetify.noData'),"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")]}},{key:"item.emergencyName",fn:function(ref){
var item = ref.item;
return [(item.emergencyContacts.length)?_c('div',[_vm._v(" "+_vm._s(item.emergencyContacts[0].name)+" ")]):_c('div',{staticClass:"text-center"},[_vm._v(" - ")])]}},{key:"item.emergencyTelephone",fn:function(ref){
var item = ref.item;
return [(item.emergencyContacts.length)?_c('div',[_vm._v(" "+_vm._s(item.emergencyContacts[0].phoneNo)+" ")]):_c('div',{staticClass:"text-center"},[_vm._v(" - ")])]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.update(item.id)}}},[_vm._v(" mdi-square-edit-outline ")]),_c('v-icon',{on:{"click":function($event){return _vm.remove(item.id)}}},[_vm._v(" mdi-delete-outline ")])],1)]}}])}),_c('div',{staticClass:"d-flex justify-center justify-md-end mt-6"},[_c('v-pagination',{attrs:{"length":_vm.totalPage,"total-visible":5},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }