<template>
  <div class="member">
    <router-view v-if="$route.name === 'MemberUpdate'" />
    <div v-else>
      <v-row class="mb-4">
        <v-col
          cols="12"
          md="8">
          <h1> {{ $t('app.page.member.title') }} </h1>
        </v-col>
        <v-col
          cols="12"
          md="2">
          <v-text-field
            :value="search"
            :placeholder="$t('app.placeholder.search')"
            prepend-inner-icon="mdi-magnify"
            dense
            outlined
            hide-details
            @input="debounceSearching($event)" />
        </v-col>
        <v-col
          cols="12"
          md="2">
          <v-select
            v-model="selectedFilter"
            :placeholder="$t('app.placeholder.filter')"
            :items="filters"
            item-text="text"
            item-value="value"
            clearable
            dense
            outlined
            hide-details />
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="isLoading"
        :loading-text="$t('vuetify.loading')"
        :no-data-text="$t('vuetify.noData')"
        hide-default-footer>
        <template v-slot:item.name="{ item }">
          {{ item.firstName }} {{ item.lastName }}
        </template>
        <template v-slot:item.emergencyName="{ item }">
          <div v-if="item.emergencyContacts.length">
            {{ item.emergencyContacts[0].name }}
          </div>
          <div
            v-else
            class="text-center">
            -
          </div>
        </template>
        <template v-slot:item.emergencyTelephone="{ item }">
          <div v-if="item.emergencyContacts.length">
            {{ item.emergencyContacts[0].phoneNo }}
          </div>
          <div
            v-else
            class="text-center">
            -
          </div>
        </template>
        <template v-slot:item.options="{ item }">
          <div class="d-flex">
            <v-icon
              class="mr-2"
              @click="update(item.id)">
              mdi-square-edit-outline
            </v-icon>
            <v-icon @click="remove(item.id)">
              mdi-delete-outline
            </v-icon>
          </div>
        </template>
      </v-data-table>
      <div class="d-flex justify-center justify-md-end mt-6">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="5" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import MemberProvider from '@/resources/axios/providers/member'

export default {
  name: 'Member',
  data () {
    return {
      page: 1,
      limit: 7,
      totalPage: 1,
      search: '',
      isLoading: false,
      headers: [
        { text: '#', sortable: false, value: 'id', align: 'center' },
        { text: this.$t('app.table.header.name'), sortable: false, value: 'name' },
        { text: this.$t('app.table.header.telephone'), sortable: false, value: 'phoneNo' },
        { text: this.$t('app.table.header.email'), sortable: false, value: 'email', align: 'center' },
        { text: this.$t('app.table.header.nationalId'), sortable: false, value: 'nationalId' },
        { text: this.$t('app.table.header.emergencyName'), sortable: false, value: 'emergencyName' },
        { text: this.$t('app.table.header.emergencyTelephone'), sortable: false, value: 'emergencyTelephone' },
        { text: '', sortable: false, value: 'options' }
      ],
      items: [],
      selectedFilter: null,
      filters: [
        {
          text: 'Username',
          value: 'username'
        },
        {
          text: 'Email',
          value: 'email'
        }
      ]
    }
  },
  watch: {
    selectedFilter () {
      this.getMember()
    },
    page () {
      this.getMember()
    }
  },
  created () {
    this.debounceSearching = this.$_.debounce(this.setSearch, 800)
  },
  mounted () {
    if (this.$route.name === 'Member') {
      this.getMember()
    }
  },
  methods: {
    ...mapActions({
      showErrorMessage: 'notification/showErrorMessage',
      closeErrorMessage: 'notification/closeErrorMessage'
    }),
    setSearch (search) {
      this.search = search
      this.getMember()
    },
    async getMember () {
      try {
        this.isLoading = true
        const res = await MemberProvider.getMemberList({
          page: this.page,
          limit: this.limit,
          filter: this.selectedFilter ? `&${this.selectedFilter}=${this.search}` : ''
        })
        if (res) {
          this.items = res.records
          this.totalPage = res.totalPage
          this.isLoading = false
        }
      } catch (error) {
        console.error(error)
      }
    },
    update (id) {
      this.$router.push({ name: 'MemberUpdate', params: { memberId: id } })
    },
    async remove (id) {
      try {
        const res = await MemberProvider.deleteMember(id)
        if (res) {
          this.showErrorMessage({
            message: this.$t('app.alert.delete'),
            type: 'success'
          })
          setTimeout(() => {
            this.closeErrorMessage()
          }, 2000)
          this.getMember()
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style scoped>
  .member {
    padding: 16px 24px;
  }
</style>
